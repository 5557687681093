import React, { useContext, useEffect } from "react"
import styled from "styled-components"

// components
import CheckoutContainer from "../components/checkoutNew/CheckoutContainer"
import ProductCard from "../components/checkoutNew/StepOne/ProductCard/ProductCard"
import { AlignContent } from "../components/shared/container.style"
import FirstStepList from "../components/checkoutNew/StepOne/FirstStepList"
import Seo from "../components/seo"
// utils
import {
  regularProductCard,
  regularText,
} from "../components/checkoutNew/StepOne/ProductCard/ProductCardStyles"

// JSON
import MainCheckout from "../../checkoutContent/mainCheckout.json"

// context API provider
import CheckoutNewContextProvider from "../Contexts/CheckoutNewContext"
import { GlobalAuthContext } from "../Contexts/GlobalAuthContext"
import { navigate } from "gatsby"

const CheckoutHolder = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1440px;
  justify-content: center;
  align-items: center;
`

const CheckoutAlignContent = styled(AlignContent)`
  gap: 25;
  @media (max-width: 1224px) {
    flex-direction: column;
    align-items: center;
  }
`

function CheckoutNew() {
  const { selectedOption, isLoggedIn } = useContext(GlobalAuthContext)

  useEffect(() => {
    if (isLoggedIn) {
      navigate("app/manage-subscription/")
    }
  }, [])

  if (typeof window === "undefined") {
    return null
  }

  return (
    <CheckoutNewContextProvider>
      <Seo title="Checkout" noindex={true} />

      <CheckoutContainer>
        <CheckoutHolder>
          <FirstStepList />
          <CheckoutAlignContent>
            <ProductCard productInfo={MainCheckout.twoYearsSubscription} />
            <ProductCard
              styles={regularProductCard}
              textStyle={regularText}
              productInfo={MainCheckout.oneYearSubscription}
            />
            <ProductCard
              styles={regularProductCard}
              textStyle={regularText}
              productInfo={MainCheckout.monthlySubscription}
            />
          </CheckoutAlignContent>
        </CheckoutHolder>
      </CheckoutContainer>
    </CheckoutNewContextProvider>
  )
}

export default CheckoutNew
